import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import logoWhite from "../assets/Logo-White.png";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      <header className="flex flex-col">
        <nav className="flex justify-between items-center w-full h-20 bg-first">
          <div
            id="logo-container"
            className="flex flex-row  w-full justify-between items-center md:ms-20 mx-4 "
          >
            <div
              id="logo-container-l"
              className="flex flex-row justify-center items-center p-0 m-0 hover:cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            >
              <img className="w-auto h-20" alt="Logo" src={logoWhite}></img>
              <h1 className="font-acquire text-white text-3xl ms-4 ps-4 border-s border-solid border-white">
                {t("headertitle")}
                <br></br>
                {t("headertitle2")}
              </h1>
            </div>

            <div
              className="text-white md:hidden cursor-pointer"
              onClick={toggleMenu}
            >
              <MenuIcon id="menu-icon" color="white"></MenuIcon>
            </div>
          </div>
          <div
            id="header-r"
            className={`md:flex md:flex-row justify-center items-center  hidden  `}
          >
            <div
              id="menu-container"
              className={`navigation ${
                isMenuOpen ? "open" : ""
              } justify-center items-center`}
            >
              <NavLink
                to="/home"
                className="transition-colors duration-300 ease-in-out  rounded-md no-underline text-white hover:bg-white hover:text-first p-2 me-1  text-3xl border-r2 font-acquire"
              >
                {t("home")}
              </NavLink>
              <NavLink
                to="/blog"
                className="transition-colors duration-300 ease-in-out  rounded-md no-underline text-white hover:bg-white hover:text-first p-2 me-1  text-3xl border-r2 font-acquire"
              >
                {t("blog")}
              </NavLink>
              <NavLink
                to="/buidl"
                className="transition-colors duration-300 ease-in-out  rounded-md no-underline text-first bg-white  hover:text-white hover:bg-first me-1 p-2 text-3xl border-r2 font-acquire"
              >
                {t("build")}
              </NavLink>
            </div>
            <div
              id="lang-container"
              className={`navigation2 ${isMenuOpen ? "open2" : ""}`}
            >
              <ul className="flex flex-col items-center me-4">
                <li
                  className="ms-4 transition-colors duration-300 ease-in-out cursor-pointer text-white text-l font-bold uppercase hover:text-warning"
                  onClick={() => changeLanguage("en")}
                >
                  EN
                </li>
                <li
                  className="ms-4 transition-colors duration-300 ease-in-out cursor-pointer text-white text-l font-bold uppercase hover:text-warning"
                  onClick={() => changeLanguage("tr")}
                >
                  TR
                </li>
                <li
                  className="ms-4 transition-colors duration-300 ease-in-out cursor-pointer text-white text-l font-bold uppercase hover:text-warning"
                  onClick={() => changeLanguage("de")}
                >
                  DE
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {isMenuOpen && (
          <div className=" flex flex-col justify-between items-center w-full h-auto bg-first">
            <div
              id="menu-container"
              className="flex flex-col justify-center items-center w-full"
            >
              <NavLink
                to="/home"
                className="text-center my-2 border-b border-white w-full transition-colors duration-300 ease-in-out  no-underline text-white hover:bg-white hover:text-first p-2 me-1  text-3xl border-r2 font-acquire"
              >
                {t("home")}
              </NavLink>
              <NavLink
                to="/blog"
                className="text-center my-2 border-b border-white w-full transition-colors duration-300 ease-in-out  no-underline text-white hover:bg-white hover:text-first p-2 me-1  text-3xl border-r2 font-acquire"
              >
                {t("blog")}
              </NavLink>
              <NavLink
                to="/buidl"
                className="text-center my-2 border-b border-white w-full transition-colors duration-300 ease-in-out  no-underline text-first bg-white  hover:text-white hover:bg-first me-1 p-2 text-3xl border-r2 font-acquire"
              >
                {t("build")}
              </NavLink>
            </div>
            <div id="lang-container" className="flex flex-row">
              <ul className="flex flex-row items-center">
                <li
                  className={`mx-4 my-2 p-1 transition-colors duration-300 ease-in-out cursor-pointer text-l font-bold uppercase hover:text-warning ${
                    i18n.language === "en" ? "text-warning " : "text-white"
                  }`}
                  onClick={() => changeLanguage("en")}
                >
                  EN
                </li>
                <li
                  className={`mx-4 my-2 p-1 transition-colors duration-300 ease-in-out cursor-pointer text-l font-bold uppercase hover:text-warning ${
                    i18n.language === "tr" ? "text-warning " : "text-white "
                  }`}
                  onClick={() => changeLanguage("tr")}
                >
                  TR
                </li>
                <li
                  className={`mx-4 my-2 p-1 transition-colors duration-300 ease-in-out cursor-pointer text-l font-bold uppercase hover:text-warning ${
                    i18n.language === "de" ? "text-warning " : "text-white"
                  }`}
                  onClick={() => changeLanguage("de")}
                >
                  DE
                </li>
              </ul>
            </div>
          </div>
        )}
      </header>
    </div>
  );
}

export default Header;
