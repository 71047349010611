import gafur from "../assets/author/gafur.png";
import arda from "../assets/author/arda.png";
import eren from "../assets/author/eren.png";
import yusuf from "../assets/author/yavuz.png";
import yavuz from "../assets/author/yavuz.png";
import user from "../assets/author/user.png";

const items = [
  {
    id: 1,
    title: "Bitcoin Nedir?",
    author: "Ethem Görkem Karakuş",
    url: "",
    imgUrl: user,
  },
  {
    id: 2,
    title: "Microsoft'un Garip Patenti",
    author: "Adil Mert Taşdoğan",
    url: "",
    imgUrl: user,
  },
  {
    id: 3,
    title: "DeFi 101",
    author: "Can Hayır",
    url: "",
    imgUrl: user,
  },
  {
    id: 4,
    title: "ERC-20'nin Anatomisi #1",
    author: "Yavuz Selim Tunçer",
    url: "",
    imgUrl: gafur,
  },
  {
    id: 5,
    title: "Merkezi Yapı Problemleri",
    author: "Gafur Apil",
    url: "",
    imgUrl: gafur,
  },
  {
    id: 6,
    title: "UST ve LUNA Krizi",
    author: "Eren Oğuz",
    url: "",
    imgUrl: eren,
  },
  {
    id: 7,
    title: "Metaverse ve Yeni Oyun Dünyası",
    author: "Gafur Apil",
    url: "",
    imgUrl: gafur,
  },
  {
    id: 8,
    title: "Digital Twin ve Blockchain İlişkisi",
    author: "Adil Mert Taşdoğan",
    url: "",
    imgUrl: user,
  },
  {
    id: 9,
    title: "Blockchain 0 ve 1'den mi İbaret?",
    author: "Gafur Apil",
    url: "",
    imgUrl: gafur,
  },
  {
    id: 10,
    title: "Anatomy of ERC-20 #2",
    author: "Yavuz Selim Tunçer",
    url: "",
    imgUrl: yavuz,
  },
  {
    id: 11,
    title: "Kripto Para ve Yatırım Psikolojisi",
    author: "Can Hayır",
    url: "",
    imgUrl: user,
  },
  {
    id: 12,
    title: "Kripto Paralar Enerji Açısından Verimsiz mi?",
    author: "Adil Mert Taşdoğan",
    url: "",
    imgUrl: user,
  },
  {
    id: 13,
    title: "Kriptolojinin Temelleri",
    author: "Yusuf Emre Mutlutürk",
    url: "",
    imgUrl: yusuf,
  },
  {
    id: 14,
    title: "Anatomy of ERC-20 #3",
    author: "Yavuz Selim Tunçer",
    url: "",
    imgUrl: yavuz,
  },
  {
    id: 15,
    title: "Günümüz Hukuk Normları ve Metaverse Evreninde Uygulanabilirliği",
    author: "Görkem Ünal",
    url: "",
    imgUrl: user,
  },
  {
    id: 16,
    title: "Merkeziyetsiz Bilim nedir?",
    author: "Arda Ural Atıcı",
    url: "",
    imgUrl: arda,
  },
  {
    id: 17,
    title: "Hash Fonksiyonları",
    author: "Yusuf Emre Mutlutürk",
    url: "",
    imgUrl: yusuf,
  },
  {
    id: 18,
    title: "Chainlink Teknolojileri",
    author: "Yavuz Selim Tunçer",
    url: "",
    imgUrl: yavuz,
  },
  {
    id: 19,
    title: "Token Ekonomisi #1",
    author: "Adil Mert Taşdoğan",
    url: "",
    imgUrl: user,
  },
  {
    id: 20,
    title: "Modern Saadet Zinciri",
    author: "Görkem Ünal",
    url: "",
    imgUrl: user,
  },
  {
    id: 21,
    title: "Web3.0'a Giriş #1",
    author: "Can Hayır",
    url: "",
    imgUrl: user,
  },
  {
    id: 22,
    title: "ERC-721'lerin Anatomisi #1",
    author: "Yavuz Selim Tunçer",
    url: "",
    imgUrl: yavuz,
  },
  {
    id: 23,
    title: "ERC-721'lerin Anatomisi #2",
    author: "Yavuz Selim Tunçer",
    url: "",
    imgUrl: yavuz,
  },
  {
    id: 24,
    title: "ERC-721'lerin Anatomisi #3",
    author: "Yavuz Selim Tunçer",
    url: "",
    imgUrl: yavuz,
  },
  {
    id: 25,
    title: "Token Ekonomisi #2",
    author: "Adil Mert Taşdoğan",
    url: "",
    imgUrl: user,
  },
  {
    id: 26,
    title: "ERC-721'lerin Anatomisi #4",
    author: "Yavuz Selim Tunçer",
    url: "",
    imgUrl: yavuz,
  },
  {
    id: 27,
    title: "Web3.0'a Giriş #2",
    author: "Can Hayır",
    url: "",
    imgUrl: user,
  },
];
export default items;
