import "./App.scss";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Blog from "./pages/Blog";
import Buidl from "./pages/Buidl";
import NotFound from "./pages/NotFound";
import { initReactI18next } from "react-i18next";
import i18n from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
i18n.use(initReactI18next).init({
  lng: "en",
  resources: {
    en: {
      translation: require("./locales/en.json"),
    },
    tr: {
      translation: require("./locales/tr.json"),
    },
    de: {
      translation: require("./locales/de.json"),
    },
  },
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
function App() {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = `${t("pagetitle")}`;
  }, [i18n.language]);
  return (
    <div>
      <Helmet>
        <title>{t("pagetitle")}</title>
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/buidl" element={<Buidl />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
