import React, { useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import blogimg from "../assets/blogimg.png";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { useTranslation } from "react-i18next";
import items from "../locales/bloginfo";
import i18next from "i18next";
import gafur from "../assets/author/gafur.png";
import arda from "../assets/author/arda.png";
import betul from "../assets/author/betul.png";
import eren from "../assets/author/eren.png";
import yusuf from "../assets/author/yusuf.png";
import yavuz from "../assets/author/yavuz.png";
import user from "../assets/author/user.png";

function Blog() {
  function getImageUrl(imageName) {
    switch (imageName) {
      case "gafur":
        return gafur;
      case "arda":
        return arda;
      case "betul":
        return betul;
      case "user":
        return user;
      case "yavuz":
        return yavuz;
      case "eren":
        return eren;
      case "yusuf":
        return yusuf;
      default:
        return "user";
    }
  }
  const localizedItems = items.map((item) => {
    return {
      ...item,
      title: i18next.t(`titles.${item.id}`),
      author: i18next.t(`authors.${item.id}`),
      url: i18next.t(`urls.${item.id}`),
      imgUrl: getImageUrl(i18next.t(`imgurls.${item.id}`)),
    };
  });
  const randomizedItems = localizedItems.sort(() => Math.random() - 0.5);

  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({});
  }, []);
  return (
    <div>
      <Header></Header>
      <section
        id="blog-container"
        className="bg-gradient-to-br from-first to-second flex flex-col min-h-screen"
      >
        <div id="blog-text" className="text-third flex flex-col mx-4 md:ms-20">
          <h2
            id="blog-title"
            className="text-center text-3xl md:text-7xl mt-4 font-acquire"
          >
            {t("blogTitle")}
          </h2>
          <div
            id="blog-text-item"
            className="flex flex-col md:flex-row items-center"
          >
            <div id="p-container" className="flex flex-col">
              <p className="w-4/5 text-l md:text-xl pb-4 m-0">
                <ArrowForwardIosIcon fontSize="small" />
                {t("blogText1")}
              </p>
              <p className="w-4/5 text-l md:text-xl pb-4 m-0">
                <ArrowForwardIosIcon fontSize="small" />
                {t("blogText2")}
              </p>
              <p className="w-4/5 text-l md:text-xl pb-4 m-0">
                <ArrowForwardIosIcon fontSize="small" />
                {t("blogText3")}
              </p>
            </div>
            <div id="blog-img" className="flex justify-center items-center">
              <img
                className="flex justify-center items-center"
                src={blogimg}
                alt="Blog"
              />
            </div>
          </div>
        </div>

        <div id="carousel">
          <Carousel
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={5000}
            centerMode={false}
            className=""
            showDots={false}
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect={false}
            infinite
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside
            responsive={{
              desktop: {
                breakpoint: { max: 3000, min: 1024 },
                items: 3,
              },
              tablet: {
                breakpoint: { max: 1024, min: 464 },
                items: 2,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
              },
            }}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {randomizedItems.map((item) => (
              <div
                key={item.id}
                id="card-container"
                className="text-white border-2 border-black border-solid bg-gradient-to-b from-third  to-white flex justify-center items-center flex-col w-[600px] h-auto"
              >
                <div
                  id="author"
                  className="flex-1 flex justify-center items-center flex-col font-semibold"
                >
                  <img
                    alt="authorImg"
                    src={item.imgUrl}
                    className="mt-4 w-40 h-auto rounded-full border border-black border-solid p-2"
                  ></img>
                  <h3 className="m-0 text-black">{item.author}</h3>
                </div>
                <div
                  id="details"
                  className="bg-fourth  flex justify-center items-center flex-col w-full m-0 py-4"
                >
                  <div id="title">
                    <h3 className="text-center my-3"> {item.title}</h3>
                  </div>
                  <div id="button">
                    <a
                      className="mt-1 no-underline bg-primary text-white  text-2xl p-3 text-center border-none rounded-lg cursor-pointer mb-2 transition duration-300 ease-linear hover:bg-darkPrimary"
                      href={item.url}
                      target="_blank "
                    >
                      {t("readButton")}
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Carousel>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default Blog;
