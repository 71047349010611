import React from "react";
import logo from "../assets/Logo-White.png";
import Header from "../components/Header";
import Footer from "../components/Footer";

function NotFound() {
  return (
    <div>
      <div>
        <div
          id="nf-ng"
          className="bg-gradient-to-b from-[#081738]  to-[#cf46c1] min-h-screen flex flex-col justify-center items-center"
        >
          <div className="flex flex-col justify-center items-center">
            <img src={logo} alt="logo" className="w-[200px] h-auto mb-2"></img>
            <h2 className="w-auto text-center border-b border-white text-white text-4xl my-8 pb-4">
              UPPSSSSS!
            </h2>
            <p className="text-white text-2xl text-center m-1">
              The page you are looking for does not exist.
            </p>

            <p className="text-white text-2xl text-center m-1">
              Maybe the cat can help you get back homeˆˆ
            </p>
            <a href="/">
              <button className="bg-gradient-to-b from-white to-[#ececec] text-black p-1 text-2xl font-instrument rounded mt-2 cursor-pointer shadow-md hover:shadow-2xl transition-all duration-500 ease-in-out">
                Home
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
