import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const date = new Date().getFullYear();

  return (
    <footer className="bg-first text-white py-8 w-full h-full ">
      <div className="flex flex-col md:flex-row items-start justify-around mx-8">
        <div className="flex flex-col items-start my-4 md:items-start justify-start md:me-20">
          <h3 className="text-white  font-extrabold mb-2">{t("contact")}</h3>
          <p className="text-white text-xl">
            <i className="fas fa-map-marker-alt"></i> Beykoz/Istanbul
          </p>
          <p className="text-white text-xl">
            <i className="fas fa-envelope"></i>
            taublockchain@kulup.tau.edu.tr
          </p>
        </div>
        <div className="flex flex-col items-start justify-start">
          <h3 className="text-white  font-extrabold mb-2">{t("follow")}</h3>
          <div className="flex justify-start items-start mt-2">
            <a
              className="text-white text-2xl mx-2 hover:opacity-75"
              href="https://www.instagram.com/taublockchain/"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-instagram"></i>
            </a>
            <a
              className="text-white text-2xl mx-2 hover:opacity-75"
              href="https://twitter.com/tdublockchain"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
            <a
              className="text-white text-2xl mx-2 hover:opacity-75"
              href="https://www.youtube.com/@taublockchain"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-youtube"></i>
            </a>
            <a
              className="text-white text-2xl mx-2 hover:opacity-75"
              href="https://medium.com/ta%C3%BC-blockchain"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-medium"></i>
            </a>
            <a
              className="text-white text-2xl mx-2 hover:opacity-75"
              href="https://www.linkedin.com/company/ta%C3%BC-blockchain"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
      <hr className="border-t border-solid border-gray-400 my-4" />
      <p className="flex justify-center items-center texk-2xl text-center w-full  pb-3">
        &copy;{date} {t("footer")}
      </p>
    </footer>
  );
};

export default Footer;
