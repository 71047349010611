import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../components/Header";
import Footer from "../components/Footer";
import logoWhite from "../assets/Logo-White.png";
import blog from "../assets/blog.png";
import event from "../assets/event.png";
import proje from "../assets/project.png";
import "animate.css";
import { useTranslation } from "react-i18next";
import gafur from "../assets/members/2.png";
import beyza from "../assets/members/4.png";
import sude from "../assets/members/3.png";
import betul from "../assets/members/5.png";
import yavuz from "../assets/members/1.png";
import mert from "../assets/members/6.png";
import { BsLink, BsLinkedin, BsGithub } from "react-icons/bs";
const members = [
  {
    img: gafur,
    name: "Gafur APIL",
    title: "Developer",
    social: [
      {
        link: "https://www.github.com/afurgapil",
        icon: BsGithub,
      },
    ],
  },
  {
    img: betul,
    name: "Betül ÇAKIROĞLU",
    title: "Social Media Manager",
    social: [
      {
        link: "https://tr.linkedin.com/in/bet%C3%BCl-%C3%A7ak%C4%B1ro%C4%9Flu",
        icon: BsLinkedin,
      },
    ],
  },
  {
    img: sude,
    name: "Sude Nur CEYLAN",
    title: "Editor",
    social: [
      {
        link: "https://tr.linkedin.com/in/sude-nur-ceylan-9aa5131b8",
        icon: BsLinkedin,
      },
    ],
  },
  {
    img: yavuz,
    name: "Yavuz Selim TUNÇER",
    title: "Smart Contract Developer",
    social: [
      {
        link: "https://linktr.ee/yavuzselimtuncer02",
        icon: BsLink,
      },
    ],
  },
  {
    img: beyza,
    name: "Beyza HORUZ",
    title: "Operations Manager",
    social: [
      {
        link: "https://www.linkedin.com/in/beyza-horuz-5bb8b11bb",
        icon: BsLinkedin,
      },
    ],
  },
  {
    img: mert,
    name: "Mert Ali HANBAY",
    title: "Mobile Developer",
    social: [
      {
        link: "https://www.linkedin.com/in/mert-ali-hanbay-482b5b200/?originalSubdomain=tr",
        icon: BsLinkedin,
      },
    ],
  },
];
const Home = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({});
    members.sort(() => Math.random() - 0.5);
  }, []);

  return (
    <div>
      <Header></Header>
      <div
        id="section1"
        className="min-h-screen bg-gradient-to-br from-first to-second flex justify-center items-center"
      >
        <div id="section1-container" className="flex flex-col md:flex-row">
          <div
            id="logo-container"
            className="flex flex-col justify-center items-center my-4 md:flex-1"
          >
            <img
              alt="Logo"
              src={logoWhite}
              className="animate__animated animate__fadeInLeft w-2/3 h-auto"
            ></img>
          </div>
          <div
            id="welcome"
            className="
            animate__animated
            animate__fadeInRight
            flex flex-col justify-center items-center text-center text-white border  border-white p-12 rounded-3xl  md:flex-1 m-10"
          >
            <h2 className="font-acquire text-5xl text-center p-0 mb-4">
              {t("name")}
            </h2>
            <p className="p-2 text-2xl">{t("p1")}</p>
            <p className="p-2 text-2xl">{t("p2")}</p>
          </div>
        </div>
      </div>

      <div
        id="section2"
        className="flex flex-col  w-full min-h-screen flex-wrap bg-gradient-to-bl from-second to-[#355e00] py-16"
      >
        <div
          id="blog"
          class="flex flex-col md:mx-10  md:flex-row justify-center items-center w-full  text-white"
          data-aos="fade-up"
        >
          <div class="flex flex-col justify-center items-center  min-h-full">
            <img className="w-auto h-1/3" src={blog} alt="Blog"></img>
          </div>
          <div class="flex flex-col justify-center items-center  md:mx-10">
            <h3 className="text-4xl font-bold mb-4 border-b-2 border-white border-groove">
              {t("blogs.title")}
            </h3>
            <p className="text-center text-xl leading-5">
              {t("blogs.content")}
            </p>
          </div>
        </div>
        <div
          id="event"
          class="flex flex-col md:mx-10  md:flex-row-reverse justify-center items-center w-full  text-white"
          data-aos="fade-up"
        >
          <div class="flex flex-col justify-center items-center   min-h-full">
            <img className="w-auto h-1/3 " src={event} alt="Event"></img>
          </div>
          <div class="flex flex-col justify-center items-center md:mx-10">
            <h3 className="text-4xl font-bold mb-4 border-b-2 border-white border-groove">
              {t("events.title")}
            </h3>
            <p className="text-center text-xl leading-5">
              {t("events.content")}
            </p>
          </div>
        </div>
        <div
          id="projects"
          class="flex flex-col  md:mx-10  md:flex-row justify-center items-center w-full  text-white"
          data-aos="fade-up"
        >
          <div class="flex flex-col justify-center items-center   min-h-full">
            <img className="w-auto h-1/3" src={proje} alt="Project"></img>
          </div>
          <div class="flex flex-col justify-center items-center md:mx-10 ">
            <h3 className="text-4xl font-bold mb-4 border-b-2 border-white border-groove">
              {t("projects.title")}
            </h3>
            <p className="text-center  text-xl leading-5">
              {t("projects.content")}
            </p>
          </div>
        </div>
      </div>
      <div
        id="section3"
        className="w-full min-h-screen bg-gradient-to-br from-[#355e00] to-[#3467c6] flex flex-col justify-center items-center"
      >
        <h2
          className="section3-title text-white text-6xl font-instrument p-0 m-1"
          data-aos="fade-down"
        >
          {t("ekip")}
        </h2>
        <div
          className="flex flex-col md:flex-row flex-wrap justify-center items-center"
          data-aos="flip-up
        "
        >
          {members.map((member) => (
            <div className="hover:transform hover:scale-110 transition-transform duration-500 ease-linear  w-3/4 md:w-1/5 lg:w-1/16 h-auto   flex flex-col justify-center items-center m-4 border-2 border-black p-8 shadow-xl">
              <img
                src={member.img}
                alt="user-img"
                className="w-1/2 h-1/2 rounded-full p-2 border border-black border-solid"
              ></img>
              <h3 className="text-2xl text-white text-center">{member.name}</h3>
              <h4 className="text-white mt-0 text-l">{member.title}</h4>
              <div className="flex flex-row">
                {member.social.map((item) => (
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noreferrer"
                    className="text-white text-2xl m-4"
                    key={item.link}
                  >
                    {item.icon && <item.icon />}
                  </a>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Home;
