import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import logoWhite from "../assets/Logo-White.png";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faYoutube,
  faInstagram,
  faTwitter,
  faLinkedin,
  faMedium,
} from "@fortawesome/free-brands-svg-icons";
const social = [
  {
    icon: faInstagram,
    title: "Instagram",
    link: "https://www.instagram.com/taublockchain/",
    animateDirection: "animate__bounceInRight",
  },
  {
    icon: faTwitter,
    title: "Twitter",
    link: "https://twitter.com/tdublockchain",
    animateDirection: "animate__bounceInLeft",
  },
  {
    icon: faYoutube,
    title: "Youtube",
    link: "https://www.youtube.com/@taublockchain",
    animateDirection: "animate__bounceInRight",
  },
  {
    icon: faLinkedin,
    title: "LinkedIN",
    link: "https://www.linkedin.com/company/ta%C3%BC-blockchain",
    animateDirection: "animate__bounceInLeft",
  },
  {
    icon: faDiscord,
    title: "Discord",
    link: "https://discord.gg/TbVh5HF2m6",
    animateDirection: "animate__bounceInRight",
  },
];
const medium = [
  {
    title: "Medium EN ",
    link: "https://medium.com/tau-blockchain-en",
  },
  {
    title: "Medium TR ",
    link: "https://medium.com/ta%C3%BC-blockchain",
  },
  {
    title: "Medium DE ",
    link: "https://medium.com/tau-blockchain-de",
  },
];
function Buidl() {
  return (
    <div>
      <Header></Header>
      <section
        id="contact-container"
        className="bg-gradient-to-br from-first to-second min-h-screen flex flex-col justify-center items-center"
      >
        <div
          id="contact-logo"
          className="animate__animated animate__backInDown w-28 rounded-full border border-spacing-1 border-solid border-white p-4 m-1"
        >
          <img src={logoWhite} alt="Logo" className="w-24 h-auto m-0"></img>
        </div>
        <div
          id="contact-title"
          className="animate__animated animate__backInDown m-0 p-0 text-xl text-white font-acquire "
        >
          <h2 className="m-0 p-0">TGU Blockchain</h2>
        </div>
        <div
          id="mail"
          className="animate__animated animate__backInDown flex items-center text-white justify-end border-b border-solid border-white"
        >
          <MailOutlineIcon fontSize="large"></MailOutlineIcon>
          <p className="text-xl ms-1">taublockchain@kulup.edu.tr</p>
        </div>
        <div
          id="link-container"
          className="flex flex-col justify-center items-center text-white min-w-full"
        >
          {social.map((item) => (
            <div
              className={`${item.animateDirection} link animate__animated  text-center group hover:bg-white mt-4 p-4 rounded-3xl w-1/2 border border-solid border-white text-2xl`}
            >
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                className="transition-colors duration-300 ease-in-out flex items-center justify-start text-white leading-4 text-xl group-hover:text-second"
              >
                <FontAwesomeIcon icon={item.icon} />
                <span className="mx-auto">{item.title}</span>
              </a>
            </div>
          ))}

          <div className=" animate__animated animate__bounceIn flex  md:flex-row justify-center items-center w-full md:w-1/2 md:text-2xl text-l mt-8 ">
            {medium.map((item) => (
              <div className="m-1 p-1  border border-solid border-white rounded-3xl w-1/2 md:w-1/3 py-2 hover:bg-white hover:text-second transition-colors duration-300 ease-in-out">
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex justify-center items-center  "
                >
                  <FontAwesomeIcon icon={faMedium} />
                  <h5 className="ms-4">{item.title}</h5>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer></Footer>
    </div>
  );
}

export default Buidl;
